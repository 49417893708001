import { Link } from '@mentimeter/next-navigation';
import { Box, Clickable } from '@mentimeter/ragnar-ui';
import React from 'react';
import { trackUser } from '@api/tracking/client';
import { TrackingPlacement } from '../../tracking';
import type { MenuItemLinkProps, MenuItemWrapperProps } from '../../types';

export function MenuItemWrapper({
  id,
  children,
  ...props
}: MenuItemWrapperProps & { children: React.ReactNode }) {
  return (
    <Box
      id={id}
      data-testid={id}
      width="100%"
      alignItems="center"
      flexDirection="row"
    >
      <MenuItemLink {...props}>{children}</MenuItemLink>
    </Box>
  );
}

export function MenuItemLink({
  to,
  target,
  placement = TrackingPlacement.MenuList,
  onClick,
  icon,
  children,
}: MenuItemLinkProps & { children: React.ReactNode }) {
  if (to) {
    return (
      <Link
        px="space3"
        py="space2"
        flex={1}
        display="inline-flex"
        alignItems="center"
        href={to}
        underline={false}
        target={target}
        onClick={() => {
          trackUser({
            event: 'Clicked menu item',
            properties: {
              link: to,
              placement,
            },
          });
        }}
        extend={({ theme }) => ({
          outlineColor: `${theme.colors.interactiveFocused}`,
          transition: `all ${theme.durations[1]}s ease`,
          borderRadius: `${theme.kosmosBorderRadius.xl}px`,
          '@media (hover: hover)': {
            ':hover': {
              opacity: 1,
              backgroundColor: theme.colors.infoWeakest,
            },
          },
          ':active': {
            opacity: 1,
            backgroundColor: theme.colors.infoWeak,
          },
          ':focus-visible': {
            boxShadow: 'none',
            outlineWidth: '4px',
            outlineStyle: 'solid',
            outlineOffset: '2px',
          },
        })}
      >
        {icon && (
          <Box display="inline-flex" mr={2}>
            {icon}
          </Box>
        )}
        {children}
      </Link>
    );
  }

  return (
    <Clickable
      px="space3"
      py="space2"
      flex={1}
      display="inline-flex"
      flexDirection="row"
      alignItems="center"
      onClick={onClick}
      borderRadius="xl"
      extend={({ theme }) => ({
        '@media (hover: hover)': {
          ':hover': {
            opacity: 1,
            backgroundColor: theme.colors.infoWeakest,
          },
        },
        ':active': {
          opacity: 1,
          backgroundColor: theme.colors.infoWeak,
        },
      })}
    >
      {icon && (
        <Box display="inline-flex" mr={2}>
          {icon}
        </Box>
      )}
      {children}
    </Clickable>
  );
}
