import { MentimeterSymbol } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui';
import { MenuItemWrapper } from '../item/components/MenuItemWrapper';
import { TrackingPlacement } from '../tracking';

export function MenuLogo() {
  return (
    <MenuItemWrapper
      id="logo-menu-item"
      to="/app/home"
      placement={TrackingPlacement.Logo}
    >
      <Box py="space1">
        <MentimeterSymbol width={32} height={32} />
      </Box>
    </MenuItemWrapper>
  );
}
