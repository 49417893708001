import { useState } from 'react';
import { Box, Tooltip, ModalRoot, ModalTrigger } from '@mentimeter/ragnar-ui';
import { HelpModal } from './HelpModal';

export function HelpModalTrigger({
  children,
  showTooltip = true,
}: {
  children: React.ReactNode;
  showTooltip?: boolean;
}) {
  const [showHelp, setShowHelp] = useState(false);

  return (
    <>
      {showTooltip && (
        <Tooltip
          referenceId="help-icon"
          placement="bottom"
          description="Get help"
        />
      )}
      <Box id="help-icon" justifyContent="center">
        <ModalRoot open={showHelp} onOpenChange={setShowHelp}>
          <ModalTrigger>
            <Box id="help-thumbnail" width="100%">
              {children}
            </Box>
          </ModalTrigger>
          <HelpModal open={showHelp} />
        </ModalRoot>
      </Box>
    </>
  );
}
