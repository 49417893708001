import { TemplateModal } from '@mentimeter/templates-modal/TemplateModal/TemplateModal';
import { ModalRoot, ModalTrigger } from '@mentimeter/ragnar-ui';
import { useState } from 'react';
import { MenuItem } from '../../../menu-ui';

export function TemplatesMenuItem() {
  const [openTemplateModal, setOpenTemplateModal] = useState(false);

  return (
    <ModalRoot open={openTemplateModal} onOpenChange={setOpenTemplateModal}>
      <ModalTrigger>
        <MenuItem compact text="Templates" id="templates-menu-item" />
      </ModalTrigger>
      {openTemplateModal && (
        <TemplateModal
          open={openTemplateModal}
          trackingContext="Dashboard menu"
          modalOpeningContext="homeview"
        />
      )}
    </ModalRoot>
  );
}
