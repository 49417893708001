import { Box, Text } from '@mentimeter/ragnar-ui';
import React from 'react';
import type { MenuItemComponentProps } from '../types';
import { MenuItemWrapper } from './components/MenuItemWrapper';
import { MenuItemInlineBadge } from './components/MenuItemInlineBadge';

export function MenuItem({
  text,
  id,
  badgeType,
  badgeText,
  compact = false,
  highlight = false,
  ...props
}: MenuItemComponentProps) {
  const [tail, ...head] = text.split(' ').reverse();
  const prefix = head.reverse().join(' ');

  return (
    <MenuItemWrapper id={id} {...props}>
      {highlight && (
        <Box
          width="2px"
          bg="secondary"
          height="45%"
          position="absolute"
          top="50%"
          left={0}
          extend={() => ({
            transform: 'translateY(-50%)',
          })}
        />
      )}
      <Text
        variant={compact ? 'dashboardBodyXs' : 'dashboardBodySm'}
        color="text"
        fontWeight={highlight ? 'semiBold' : 'regular'}
      >
        {prefix && <>{prefix} </>}
        {tail && (
          <Box as="span" display="inline-block">
            {tail}
          </Box>
        )}
        {(props.target || badgeType) && (
          <Box display="inline-block">
            {badgeType &&
              (badgeText ? (
                <MenuItemInlineBadge badgeType={badgeType} text={badgeText} />
              ) : (
                <MenuItemInlineBadge badgeType={badgeType} />
              ))}
          </Box>
        )}
      </Text>
    </MenuItemWrapper>
  );
}
