import React from 'react';
import { FooterItems, MenuItem } from 'features/menu-ui';
import { TemplatesMenuItem } from '../TemplatesMenuItem';
import { Integrations } from '../../../../integrations';
import { HelpModalTrigger } from '../../header/user-actions/components/HelpModalTrigger';

export const FooterSection = () => (
  <FooterItems>
    <TemplatesMenuItem />
    <Integrations />
    <MenuItem
      text="Menti Academy"
      to="https://academy.mentimeter.com/"
      id="menti-academy-menu-item"
      target="_blank"
      compact
    />
    <HelpModalTrigger showTooltip={false}>
      <MenuItem id="help-and-support-menu-item" text="Help & support" compact />
    </HelpModalTrigger>
    <MenuItem compact id="trash-menu-item" to="/app/trash" text="Trash" />
  </FooterItems>
);
