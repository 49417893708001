import { Device } from '@mentimeter/ragnar-device';
import { Box } from '@mentimeter/ragnar-ui';
import type { ReactNode } from 'react';

export function MenuWrapper({
  children,
  noPadding = false,
}: {
  children: ReactNode;
  noPadding?: boolean | undefined;
}) {
  return (
    <Device.Match greaterThan={2}>
      <Box
        bg="bg"
        height="100%"
        width="100%"
        extend={() => ({
          '@media print': {
            display: 'none',
          },
        })}
        pl={noPadding ? 'space0' : 'space8'}
        pt={noPadding ? 'space0' : 'space6'}
        pr={noPadding ? 'space0' : 'space2'}
        pb={noPadding ? 'space0' : 'space12'}
        gap="space10"
      >
        {children}
      </Box>
    </Device.Match>
  );
}
