import { TrackingContext } from '@mentimeter/http-clients';
import {
  Box,
  Button,
  Clickable,
  Image,
  Link,
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalRoot,
  ModalTrigger,
  Text,
} from '@mentimeter/ragnar-ui';
import {
  MSTeamsIconFilled,
  PowerPointIcon,
  ZoomIcon,
} from '@mentimeter/ragnar-visuals';
import React, { useState } from 'react';
import { MenuItem } from 'features/menu-ui';
import { trackUser } from '@api/tracking/client';
import msteamsScreenshot from './assets/msteams-screenshot.png';
import powerpointScreenshot from './assets/powerpoint-screenshot.png';
import zoomScreenshot from './assets/zoom-screenshot.png';
import { createTrackEvent } from './track';

export type IntegrationsModalTabKeys =
  | 'explore'
  | 'msteams'
  | 'zoom'
  | 'powerpoint';

const TABS: { key: IntegrationsModalTabKeys; label: string }[] = [
  {
    key: 'explore',
    label: 'Explore',
  },
  {
    key: 'msteams',
    label: 'Microsoft Teams',
  },
  {
    key: 'zoom',
    label: 'Zoom',
  },
  {
    key: 'powerpoint',
    label: 'PowerPoint',
  },
];

const Screenshot = ({
  src,
  width,
  height,
  alt,
}: {
  src: string;
  width: number;
  height: number;
  alt: string;
}) => (
  <Box
    mt={3}
    borderRadius={2}
    overflow="hidden"
    bg="neutralWeakest"
    width="100%"
    style={{ aspectRatio: (width / height).toString() }}
  >
    <Image src={src} alt={alt} />
  </Box>
);

type DataKeys = Exclude<IntegrationsModalTabKeys, 'explore'>;

interface ExploreItem {
  id: DataKeys | string;
  name: string;
  icon: React.ReactNode;
}
const EXPLORE: {
  presentationTools: ExploreItem[];
  videoConferencing: ExploreItem[];
} = {
  presentationTools: [
    {
      id: 'powerpoint',
      icon: <PowerPointIcon size={7} />,
      name: 'PowerPoint',
    },
  ],
  videoConferencing: [
    {
      id: 'msteams',
      icon: <MSTeamsIconFilled size={7} />,
      name: 'Teams',
    },
    {
      id: 'zoom',
      icon: <ZoomIcon size={7} />,
      name: 'Zoom',
    },
  ],
};

const DATA: Record<
  DataKeys,
  {
    heading: string;
    text: string;
    image: { src: string; width: number; height: number };
    alt: string;
    cta: string;
    url: string;
  }
> = {
  msteams: {
    heading: 'Microsoft Teams app',
    text: 'Present directly in a Teams meeting. Your participants will automatically see your Menti and the voting view. You don’t even have to share your screen.',
    image: msteamsScreenshot,
    alt: 'Mentimeter in Microsoft Teams screenshot',
    cta: 'Open in Teams',
    url: 'https://teams.cloud.microsoft/l/app/6aa4a751-8a6a-429e-b924-1360ed272414',
  },
  zoom: {
    heading: 'Zoom app',
    text: 'Present your Menti directly in a Zoom call. Your participants will automatically see your Menti and the voting view. You don’t even have to share your screen.',
    image: zoomScreenshot,
    alt: 'Mentimeter in Zoom App screenshot',
    cta: 'Open in Zoom',
    url: 'https://www.mentimeter.com/_integrations/api/zoom/install',
  },
  powerpoint: {
    heading: 'PowerPoint Add-in',
    text: 'No more need to switch apps and screens. Create, edit and present your Menti directly in PowerPoint.',
    image: powerpointScreenshot,
    alt: 'Mentimeter in PowerPoint screenshot',
    cta: 'Get it now',
    url: 'https://appsource.microsoft.com/en-us/product/office/WA104379261',
  },
};

const IntegrationContent = ({ tab }: { tab: DataKeys }) => {
  const { heading, text, image, alt, cta, url } = DATA[tab];

  return (
    <>
      <Text mb={2} color="text" fontSize={4} fontWeight="semiBold">
        {heading}
      </Text>
      <Text>{text}</Text>
      <Screenshot
        src={image.src}
        width={image.width}
        height={image.height}
        alt={alt}
      />
      <Box mt={3} width="100%" alignItems="flex-end">
        <Button
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href={url}
          variant="primary"
          onClick={() => {
            trackUser(
              createTrackEvent({
                event: 'Clicked extensions CTA',
                properties: {
                  context: TrackingContext.MyPresentations,
                  placement: window.location.pathname,
                  integration: tab,
                  text: cta,
                },
              }),
            );
          }}
        >
          {cta}
        </Button>
      </Box>
    </>
  );
};

const ExploreItem = ({
  id,
  name,
  renderIcon,
  onClick: _onClick,
}: {
  id: DataKeys | string;
  name: string;
  renderIcon: React.ReactNode;
  onClick: (id: DataKeys) => void;
}) => {
  const [disabled, setDisabled] = useState(false);
  const onClick = React.useCallback(() => {
    trackUser(
      createTrackEvent({
        event: 'Clicked extensions app icon',
        properties: {
          context: TrackingContext.MyPresentations,
          placement: window.location.pathname,
          integration: id,
        },
      }),
    );
    if (disabled) return;

    const data = DATA[id as DataKeys];
    if (!data) {
      return setDisabled(true);
    }
    _onClick(id as DataKeys);
  }, [id, _onClick, disabled]);

  React.useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (disabled) {
      timer = setTimeout(() => {
        setDisabled(false);
      }, 5000);
    }
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      timer && clearTimeout(timer);
    };
  }, [disabled]);

  return (
    <Clickable
      flex={1}
      width="100%"
      alignItems="center"
      justifyContent="center"
      bg="bgStrong"
      p={4}
      borderRadius={1}
      onClick={onClick}
      aria-label={`Explore ${name}`}
    >
      <Box style={{ opacity: disabled ? 0.5 : 1 }}>{renderIcon}</Box>
      <Text color="text" mt={3}>
        {disabled ? 'Not yet available' : name}
      </Text>
    </Clickable>
  );
};

const Explore = ({
  setTab,
}: {
  setTab: (tab: IntegrationsModalTabKeys) => void;
}) => {
  const onClick = React.useCallback(
    (id: DataKeys) => {
      setTab(id);
    },
    [setTab],
  );

  return (
    <Box width="100%">
      <Text fontSize={3} color="text" fontWeight="semiBold" mb={3}>
        Connect Menti with your favorite tools
      </Text>
      <Text mb={2} color="text">
        Presentation
      </Text>
      <Box
        width="100%"
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr"
        columnGap={3}
        gridTemplateRows="2"
      >
        {EXPLORE.presentationTools.map((d) => (
          <ExploreItem
            key={d.id}
            id={d.id}
            name={d.name}
            renderIcon={d.icon}
            onClick={onClick}
          />
        ))}
      </Box>
      <Text mt={4} mb={2} color="text">
        Video conferencing
      </Text>
      <Box
        width="100%"
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr"
        columnGap={3}
        gridTemplateRows="2"
      >
        {EXPLORE.videoConferencing.map((d) => (
          <ExploreItem
            key={d.id}
            id={d.id}
            name={d.name}
            renderIcon={d.icon}
            onClick={onClick}
          />
        ))}
      </Box>
    </Box>
  );
};

export const IntegrationsModal = ({
  renderTrigger,
  tab,
  setTab,
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  tab: IntegrationsModalTabKeys;
  setTab: (tab: IntegrationsModalTabKeys) => void;
  renderTrigger?: React.ReactNode;
}) => {
  return (
    <ModalRoot open={open} onOpenChange={onOpenChange}>
      {renderTrigger}
      <ModalContainer width="100%" maxWidth="960px">
        <ModalHeader>Integrations</ModalHeader>
        <ModalBody>
          <Box width="100%" height="1px" bg="neutral" mb={4} />
          <Box width="100%" flexDirection="row">
            <Box width={200}>
              {TABS.map((t) => (
                <Link
                  mb={2}
                  aria-label={`Show ${t.label} details`}
                  key={t.key}
                  onClick={() => {
                    setTab(t.key);
                    trackUser(
                      createTrackEvent({
                        event: 'Clicked extensions app tab',
                        properties: {
                          context: TrackingContext.MyPresentations,
                          placement: window.location.pathname,
                          integration: t.key,
                        },
                      }),
                    );
                  }}
                  underline={false}
                  color={tab === t.key ? 'brand' : 'textWeak'}
                >
                  {t.label}
                </Link>
              ))}
            </Box>

            <Box width="100%" flex={1} key={tab}>
              {tab === 'explore' ? (
                <>
                  <Explore setTab={(tab) => setTab(tab)} />
                  <Box width="100%" mt={4} alignItems="flex-end">
                    <Button
                      as="a"
                      variant="subtle"
                      href="https://mentimeter.canny.io"
                      target="_blank"
                    >
                      Request integration
                    </Button>
                  </Box>
                </>
              ) : (
                <IntegrationContent tab={tab} />
              )}
            </Box>
          </Box>
        </ModalBody>
      </ModalContainer>
    </ModalRoot>
  );
};

export const Integrations = (props: { icon?: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState<IntegrationsModalTabKeys>('explore');

  return (
    <IntegrationsModal
      open={open}
      onOpenChange={setOpen}
      tab={tab}
      setTab={setTab}
      renderTrigger={
        <ModalTrigger
          onClick={() => {
            trackUser(
              createTrackEvent({
                event: 'Clicked extensions',
                properties: {
                  context: TrackingContext.MyPresentations,
                  placement: window.location.pathname,
                },
              }),
            );
          }}
        >
          <MenuItem
            id="integrations-menu-item"
            text="Integrations"
            compact
            {...props}
          />
        </ModalTrigger>
      }
    />
  );
};
