import type { BadgeRibbonT } from '@mentimeter/ragnar-ui';
import { Box, Badge } from '@mentimeter/ragnar-ui';
import React from 'react';

export function MenuItemInlineBadge({
  badgeType,
  text,
}: {
  badgeType: BadgeRibbonT;
  text?: string;
}) {
  return (
    <Box display="inline-block" ml={1} top="-1px">
      <Badge type={badgeType} text={text} compact maxHeight="23px" />
    </Box>
  );
}
