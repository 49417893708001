import type { ReactNode } from 'react';
import type { UserFeaturesTypeEnum } from '@mentimeter/http-clients';
import { useFeatureAccess } from '../hooks/use-feature-access';

interface Props {
  enabled: boolean;
  requiredPlan: UserFeaturesTypeEnum;
  requestUpgradeContent: ReactNode;
  selfUpgradeContent: ReactNode;
  children: ReactNode;
}

export function Paywall({
  enabled,
  requiredPlan,
  children,
  requestUpgradeContent,
  selfUpgradeContent,
}: Props) {
  const accessLevel = useFeatureAccess(requiredPlan);
  if (!enabled || accessLevel === 'has-access') return children;

  if (accessLevel === 'not-in-role') {
    return requestUpgradeContent;
  }

  return selfUpgradeContent;
}
