type TrackingData =
  | {
      event: 'Clicked extensions';
      properties: {
        context: string;
        placement: string;
      };
    }
  | {
      event: 'Clicked extensions app icon';
      properties: {
        context: string;
        placement: string;
        integration: string;
      };
    }
  | {
      event: 'Clicked extensions app tab';
      properties: {
        context: string;
        placement: string;
        integration: string;
      };
    }
  | {
      event: 'Clicked extensions CTA';
      properties: {
        context: string;
        placement: string;
        integration: string;
        text: string;
        action?: 'download';
      };
    };

export const createTrackEvent = (data: TrackingData) => data;
